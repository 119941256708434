import "../scss/main.scss";

const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
        ? ((top > 0 && top < innerHeight) ||
              (bottom > 0 && bottom < innerHeight)) &&
              ((left > 0 && left < innerWidth) ||
                  (right > 0 && right < innerWidth))
        : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

const el = document.getElementById("form");
let in_view = elementIsVisibleInViewport(el);
const scroll_down = document.getElementById("scroll-down");

if (in_view == true) {
    scroll_down.classList.add("hidden");
} else {
    scroll_down.classList.remove("hidden");
}

window.onscroll = function (e) {
    in_view = elementIsVisibleInViewport(el);
    if (in_view == true) {
        scroll_down.classList.add("hidden");
    } else {
        scroll_down.classList.remove("hidden");
    }
};
